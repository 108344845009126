/* .suitDisplay .row {
  border: 1px solid white !important;
  border-radius: 15px !important;
  padding: 20px !important;
}

.suitDisplay {
  padding: 0px 20px 0px 20px;
}

.suitText {
  padding: 10px;
  font-size: 20px;
} */

@font-face {
  font-family: "Comfortaa";
  src: url("../Fonts/Comfortaa-Bold.ttf");
}

.suitText span {
  font-family: "Comfortaa" !important;
  display: block;
  color: #78b7e5;
  font-weight: 500;
}

#suits {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
