.Vdividor {
  flex-direction: row;
  display: flex;
  justify-content: center;
}

.Vdividor img {
  align-self: center;
}

#team {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
