.section-title {
  color: white;
  font-weight: 600;
  text-decoration: underline;
  text-decoration-color: #78b7e5;
  text-underline-offset: 20px;
  padding: 0;
  margin: 0;
  line-height: 0.5;
  text-align: center;
  outline: none;
  /* background-color: #78b7e5; */
  border: none;
  border-radius: 10px;
  /* box-shadow: 0 4px #473f78; */
}
