@media only screen and (min-width: 1000px) {
  .cardsList li {
    border-radius: 5px;
    font-size: small;
    list-style: none;
  }

  .cardsList {
    margin: 0 !important;
    padding: 0 !important;
  }
}

@media only screen and (max-width: 1000px) {
  .cardsList li {
    list-style: none !important;
  }

  .cardsList {
    padding: 0;
    padding-bottom: 20px;
  }
}

#cards {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.cardTitle {
  font-family: "Comfortaa" !important;
  display: block;
  color: #78b7e5;
  font-weight: 500;
}
