@media only screen and (max-width: 1000px) {
  .ReactModal__Content {
    margin-top: 20%;
    margin-right: 5%;
    margin-left: 5%;
  }

  .Modal {
    background-color: black;
    color: white !important;
    border-radius: 10px;
    padding: 5%;
  }
}

@media only screen and (min-width: 1000px) {
  .ReactModal__Content {
    margin-top: 10%;
    margin-right: 25%;
    margin-left: 25%;
  }

  .Modal {
    background-color: black;
    color: white !important;
    border-radius: 10px;
    padding: 5%;
  }
}
