.startupBadge {
  transition: transform 1s;
  z-index: -1;
}

.startupBadge:hover {
  transform: translate(0px, -100px);
}

.parallax {
  /* The image used */
  background-image: url("../Images/websitebanners/1920X1216.png") !important;

  /* Set a specific height */
  min-height: 500px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
